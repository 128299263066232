html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

body {
  --primary-color-text: white;
  --primary-color-background: black;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--primary-color-text);
  background-color: var(--primary-color-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:global(#root) {
  width: 100vw;
  min-height: 100vh;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;

  display: grid;
  grid-template: "header" "main" "footer";
  grid-template-rows: auto 1fr auto; 
}

a {
  color: inherit; 

  &:hover{
    opacity: 0.8;
  }
}