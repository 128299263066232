.footer {
  grid-area: footer;
  padding: 40px;

  > * {
    display: inline-block;
    margin: 0 10px;

    > svg{
      height: 30px;
      width: 30px;
    }
  }
}